function akelWarranty() {

    if ( $j('.checklist-label').length ) {
        var lastClickTime = 0;
        function loadChecklist() {
            $j.ajax({
                type: 'POST',
                url: ajaxurl,
                data: {
                    action: 'load_checklist',
                },
                success: function(response) {
                    if (response.success) {
                        var delay = 0;
                        $j('.checklist-label').each(function() {
                            var el = $j(this);
                            var taskName = el.find('span b').text();
                            if (response.data[taskName] && response.data[taskName].completed) {
                                setTimeout(function() {
                                    el.find('input').prop('checked', true);
                                    el.find('.right').text(response.data[taskName].date).fadeIn(200);
                                }, delay);
                                delay += 100;
                            }
                        });
                    } else {
                        console.log('Error:', response.data);
                    }
                },
                error: function(error) {
                    console.log('Error:', error);
                }
            });
        }
        loadChecklist();

        $j('.checklist-label').on('click', function() {
            var now = new Date().getTime();
            if (now - lastClickTime < 150) return;
            lastClickTime = now;
            var el = $j(this);
            var input = el.find('input');
            var title = el.find('b').text();
            var completed = true;
            var inputVal = input.is(':checked') ? input.val() : '';
            if (inputVal) {
                el.find('span.right').fadeOut(200).delay(100).text('');
                log('empty');
                completed = false;
            }
            $j.ajax({
                type: 'POST',
                url: ajaxurl,
                data: {
                    action: 'submit_checklist',
                    title: title,
                    val: inputVal,
                    completed: completed
                },
                success: function(response) {
                    if ( completed ) {
                        console.log('Success:', response);
                        el.find('span.right').fadeIn(200).text(response);
                    }
                },
                error: function(error) {
                    console.log('Error:', error);
                }
            });
        });
    }


    var user = getUrlVars()['user'];
    var key = getUrlVars()['key'];
    var user_login = getUrlVars()['user_login'];
    var reply = getUrlVars()['reply'];
    var comments = getUrlVars()['comments'];
    var contractor = getUrlVars()['contractor'];

    $j('.btn-update-gal-all').click(function(e){
        e.preventDefault();
        var all_btn = $j(this);

        if ( all_btn.hasClass('working') ) { return; }
        all_btn.addClass('working');
        all_btn.attr('disabled', 1);
        var dash_url = all_btn.attr('href');
        var batch_size = parseInt(all_btn.attr('data-size'),10);
        var ids = [];
        var loader_html = '<div class="loader"><div class="animation"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle></svg></div></div>';
        $j('.btn-update-gal').each(function(index){
            if(index < batch_size){
                ids.push($j(this).attr('data-id'));
                $j(this).html(loader_html).addClass('working');
            }
        });
        var id_param = ids.join(',');
        var url = dash_url + '?update=1&ids=' + id_param + '&json=1';
        $j.ajax({
            type:'GET',
            url:url,
            dataType:'html text'
        }).done(function(data){
            $j('.btn-update-gal.working').each(function(index){
                if(index < batch_size){
                    $j(this).after('Updated!');
                    $j(this).remove();
                }
            });
            if ( $j('.btn-update-gal').length ) {
                setTimeout(() => {
                    all_btn.removeClass('working');
                    $j('.btn-update-gal-all').click();
                }, 2000);
            } else {
                all_btn.removeClass('working');
                location.reload();
            }
        });
    });


    $j('.btn-update-gal').click(function(e){
        e.preventDefault();
        var el = $j(this);
        var href = el.attr('href');
        var loader_html = '<div class="loader"><div class="animation"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle></svg></div></div>';
        el.html(loader_html).addClass('working');
        $j.ajax({
            type: 'GET',
            url: href+'&json=1',
            dataType: 'html text'
        }).done(function(data){
            var data = JSON.parse(data) || data;
            el.removeClass('working');
            el.text(data.status)
            log(data);
            el.after('Updated!');
            el.remove();
            if ( el.hasClass('continue') ) {
                $j('a.btn-update-gal.continue:first').click();
            }
        });
    });

    $j('.btn-cc').on('click', function(e) {
        e.preventDefault();
        var btn = $j(this);
        var url = btn.attr('href');
        btn.addClass('working');
        $j.ajax({
            url: url,
            method: 'GET',
            success: function() {
                location.reload();
            },
            error: function() {
                btn.removeClass('working').addClass('btn-error');
            },
            complete: function() {
                setTimeout(function() {
                    btn.removeClass('working');
                }, 2000);
            }
        });
    });

    if ( !! $j('.tml-login').length ) {
        setTimeout(() => {
            $j('.tml-login').fadeTo(300,1);
            var text = $j('label[for="user_login"]').text();
            $j('label[for="user_login"]').text('Email Address');
        }, 50);
    }
    $j('.btn-email-history').click(function(){
        var el = $j(this);
        if ( el.hasClass('active') ) {
            el.html('View Email History <i class="fas fa-chevron-down ml5"></i>')
            el.removeClass('active');
            $j('#email-history').slideUp(500, 'easeInOutExpo');
        } else {
            el.html('Hide Email History <i class="fas fa-chevron-up ml5"></i>')
            el.addClass('active');
            $j('#email-history').slideDown(500, 'easeInOutExpo');
        }
    });
    var lotfn = null;
    $j('.data-table-wrap').on('scroll touchmove', function() {
        var els = $j('.data-table tr td:first-child, .data-table tr th:first-child');
        var horizontalScroll = $j(this).scrollLeft();
        horizamount = horizontalScroll - 1;
        els.css({'left': horizamount+'px'});
        els.fadeTo(0,0);
        clearTimeout(lotfn);
        lotfn = setTimeout(function() {
            els.fadeTo(200,1);
        },150);
    });

    var tooltip = null;
    $j('.data-table:not(.no-tooltip) td').on('touchstart, hover, click', function() {
        // Get the index of the current td within its row
        $j('.data-tooltip').fadeOut(150);
        var el = $j(this);
        var index = $j(this).index();
        // Find the corresponding nth-child td in the thead
        var table = $j(this).closest('.data-table');
        var nthChild = table.find('thead th').eq(index);
        // Get the text content of the corresponding nth-child th
        var tooltipText = nthChild.text();
        // Add tooltip to the td
        el.addClass('relative');
        el.find('.data-tooltip').remove();
        el.append('<div class="data-tooltip">' + tooltipText + '</div>');
        el.find('.data-tooltip').fadeIn(300);
        clearTimeout(tooltip);
        tooltip = setTimeout(function() {
            $j('.data-tooltip').fadeOut(150);
        },1000);

    });

    $j('.home-progress-update.gal .header-toggle, .homeowner-gallery.gal .toggle').click(function(){
        var el = $j(this).parent();
        if ( el.hasClass('active') ) {
            el.removeClass('active');
            el.find('.updates-gallery').stop(true,false).slideUp(500, 'easeInOutExpo');
        } else {
            //el.html('Hide Email History <i class="fas fa-chevron-up ml5"></i>')
            el.addClass('active');
            el.find('.updates-gallery').stop(true,false).slideDown(500, 'easeInOutExpo');
            setTimeout(() => {
                bLazy.revalidate();
            }, 500);
        }
    });

    $j('.btn-toggle-home-filters').click(function(){
        var el = $j(this);
        if ( $j('div.home-filters.hide').length ) {
            $j('div.home-filters').removeClass('hide');
        } else {
            $j('div.home-filters:not(.start)').addClass('hide');
        }
        if ( $j('div.home-filters.start').length ) {
            $j('div.home-filters').removeClass('start');
            if ( $j( window ).width() > 1000 ) {
                $j('div.home-filters').addClass('hide');
            }
        }

    });


    if ( $j('#data-table2').length && $j( window ).width() > 1000 ) {
        if ( $j('.pager-data .pagination').length ) {
            $j('.pager-data .pagination').addClass('dynamic');
        }
        if (typeof $j !== 'undefined' && typeof $j.fn.DataTable === "function") {
            var table = $j('#data-table').DataTable({
                paging: false,
                scrollY: false,
                scrollX: false,
                searching: true,
                colReorder: true,
                fixedHeader: {
                    header: false,
                    footer: false
                }
            });
            $j('#data-table tbody')
            .on( 'mouseenter', 'td', function () {
                var colIdx = table.cell(this).index().column;
                //$j( table.cells().nodes() ).removeClass( 'highlight' );
                //$j( table.column( colIdx ).nodes() ).addClass( 'highlight' );
            } );
            $j('input[name="q"]').on('keyup', function(){
                var el = $j(this);
                var val = el.val();
            });
        }
    }

    if ( reply ) {
        var destination = $j('#customer-comments').offset().top || 0;
        $j("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 1200, 'easeInOutExpo', function() {
            $j('.newcomment-form').slideDown(300);
            $j('.btn.ctc').addClass('open');
        });
    }
    if ( comments ) {
        var destination = $j('#updates-anchor').offset().top || 0;
        $j("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 1200, 'easeInOutExpo', function() {
        });
    }
    if ( contractor && contractor == 1 ) {
        var destination = $j('#contractor-comments').offset().top || 0;
        $j("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 1200, 'easeInOutExpo', function() {
            $j('.contractor-form').slideDown(300);
            $j('.btn.cpc').addClass('open');
        });
    }
    if ( user ) {
        $j('#user_login').val(user);
    }
    if ( user_login ) {
        $j('#user_login').val(user_login);
    }
    if ( key ) {
        $j('#user_pass').val(key);
    }

    if ( user && key && ! $j('body').hasClass('logged-in') ) {
        setTimeout(function(){
            $j('.tml-button').click();
        },250);
    }

    if (  $j('body').hasClass('logged-in') && $j('form[name="lostpassword"]').length )  {
        $j('.tml-login-link').hide();
    }

    if ( user_login && $j('form[name="lostpassword"]').length ) {
        setTimeout(function(){
            $j('.tml-button').click();
            $j('form[name="lostpassword"]').slideUp(300);
        },250);
    }

    $j('form.pw-update').on('submit', function(e){
        var form = $j(this);
        form.parsley().validate();
        if (form.parsley().isValid()){
            form.find('button[type="submit"]').addClass('working');
            form.find('button[type="submit"]').attr('disabled', 'disabled');
        } else {
            e.preventDefault();
        }
    });
    $j('form.pw-update input').focus(function(){
        var el = $j(this);
        el.attr('type','text');
    });
    $j('form.pw-update input').blur(function(){
        var el = $j(this);
        el.attr('type','password');
    });


    setTimeout(function(){
        $j('#back-to-akel').removeClass('scrolled');
    }, 300);

    $j('.switch>input:not(#home_filters)').change(function(){
        var el = $j(this);
        var name = el.attr('name');
        //log(name);
        var enabled = false;
        if(this.checked) {
            enabled = true;
            el.parent().parent().find('span[data-status="'+name+'"]').text('On');
            if ( name == 'shareable' )  {
                $j('ul.share').parent().slideDown(300);
            }
        } else {
            el.parent().parent().find('span[data-status="'+name+'"]').text('Off');
            if ( name == 'shareable' )  {
                $j('ul.share').parent().slideUp(300);
            }
        }
        $j.ajax({
            type: 'POST',
            url: window.location.href,
            data: name+'='+enabled,
            dataType: 'html text'
        }).done(function(data){
            var data = JSON.parse(data) || data;
            //log(data);
        });
    });

    $j('.btn-fetch-all').click(function(e){
        $j('.btn-fetch').addClass('continue');
        $j('.btn-fetch:first').click();
    });
    $j('.btn-fetch').click(function(e){
        e.preventDefault();
        var el = $j(this);
        var href = el.attr('href');
        var loader_html = '<div class="loader"><div class="animation"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle></svg></div></div>';
        el.html(loader_html).addClass('working');
        $j.ajax({
            type: 'GET',
            url: href,
            dataType: 'html text'
        }).done(function(data){
            $j.ajax({
                type: 'GET',
                url: href+'&json=1',
                dataType: 'html text'
            }).done(function(data){
                var data = JSON.parse(data) || data;
                el.removeClass('working');
                el.text(data.status)
                if ( el.hasClass('continue') ) {
                    el.removeAttr('class');
                    $j('a.btn-fetch.continue:first').click();
                } else {
                    el.removeAttr('class');
                }
            });
        });
    });

    var acfform = $j('.acf-form-gt');

    if ( acfform.length ) {

        //var today = new Date();
        //log(today.getMonth(), today.getDay(), today.getYear())

        $j('span.start-field').each(function(index){
            var el = $j(this);
            var id = el.data('id');
            var val = el.text();

            $j('input#'+id).val(val);
            $j('select#'+id).val(val);

            if ( val != '') {
                $j('input#'+id).attr('readonly', 'readonly');
                $j('select#'+id).attr('readonly', 'readonly');
                $j('select[readonly="readonly"] option:not(:selected)').attr('disabled',true);
                if ( id == 'acf-field_5d8eac8a07588' ) {
                    $j('#acf-_post_title').val(val).attr('readonly', 'readonly');
                }
                if ( id == 'acf-field_5dba393578145' ) {
                    var cid = el.data('cid');
                    log(cid);
                    $j('select#'+id).prepend('<option value="'+cid+'" selected="">'+val+'</option>');
                    $j('select#'+id).attr('readonly', 'readonly');
                    $j('select[readonly="readonly"] option:not(:selected)').attr('disabled',true);
                }
            }
        });

        var ticketid = makechar(3)+'-'+makeid(6)
        $j('#acf-_post_title').val(ticketid);
    }


    $j('.warranty-faqs h2').click(function(e){
        e.preventDefault();
        var el = $j(this);
        if ( el.hasClass('active') ) {
            el.next('div').stop(true, false).slideUp(600,'easeInOutExpo');
            el.find('span.icon').html('<i class="fal fa-plus"></i>');
            el.removeClass('active');
        } else {
            el.next('div').stop(true, false).slideDown(600,'easeInOutExpo');
            el.find('span.icon').html('<i class="fal fa-minus"></i>');
            el.addClass('active');
        }
    });

    $j('.warranty-faqs li:first-child h2').click();


    $j('.btn-add-reply').click(function(e){
        var el = $j(this);
        if ( el.hasClass('open') ) {
            el.removeClass('btn-primary-outline').addClass('btn-primary');
            el.parent().parent().next('.comment-form').find('form').slideUp(300);
            el.removeClass('open');
        } else {
            el.parent().parent().next('.comment-form').find('form').slideDown(300);
            el.addClass('btn-primary-outline').removeClass('btn-primary');
            el.addClass('open');
        }
    });

    $j("form.myakel-ajax").on('submit', function(e){
        var form = $j(this);
        e.preventDefault();
        form.parsley().validate();
        if (form.parsley().isValid()){
            form.find('button[type="submit"]').addClass('working');
            form.find('button[type="submit"]').attr('disabled', 'disabled');
            formPost(form);
        }
    });
    $j("form.bulk-send").on('submit', function(e){
        var form = $j(this);
        e.preventDefault();
        form.parsley().validate();
        if (form.parsley().isValid()){
            form.find('button[type="submit"]').addClass('working');
            form.find('button[type="submit"]').attr('disabled', 'disabled');
            form.off('submit');
            form.submit();
        } else {
            form.find('button[type="submit"]').removeClass('working');
            form.find('button[type="submit"]').removeAttr('disabled');
        }
    });

    function formPost(form) {
        var template = $j('button[type="submit"].working').parent().find('input[name="template"]').val();
        var action = $j('button[type="submit"].working').parent().find('input[name="action"]').val();
        var formData =  form.serializeArray();
        $j.ajax({
            type: 'POST',
            url: window.location.href,
            data: formData,
            dataType: 'html text'
        }).done(function(data){
            if ( template != 'update_status' ) {
                var button = form.find('button[type="submit"]');
                button.removeClass('working').addClass('success').removeAttr('disabled');
            }
            if ( checkJSON(data) ) {
                data = JSON.parse(data);
            } else {
                error = true;
            }
            if ( data.valid ) {
                if ( template == 'update_status' ) {
                    document.location.href = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
                    return;
                    $j('.service-text').slideUp(300);
                    $j('div.customer-comments').append(data.html);
                    $j('form.serviceupdate-form ol, .service-update-intro').slideUp(300);
                    $j('form.serviceupdate-form ol').after('<h2 class="mt0">Success!</h2><p>Thank you for updating your service request status!</p> <a href="'+window.location.href+'" class="btn btn-primary status-update-continue">Continue <i class="fas fa-chevron-right"></i></a>')
                    document.location.href = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
                }
                if ( template == 'newcomment' ) {
                    $j('div.customer-comments').prepend(data.html);
                    $j('.newcomment-form').slideUp(300);
                }
                if ( template == 'contractorcomment' ) {
                    $j('div.contractor-comments').prepend(data.html);
                    $j('.contractor-form').slideUp(300);
                }
                if ( template == 'newcomment' || template == 'contractorcomment') {
                    $j('.btn-add-reply').removeClass('open');
                    $j('.new-comment').slideDown(300);
                    $j('[name="comment"]').val('');
                }
                if ( template == 'design' ) {
                    form.find('ol').slideUp(300);
                    form.find('p.welcome').slideUp(300);
                    form.find('ol').after('<div style="display:none;" id="design-result">'+data.success+'</div>');
                    form.find('#design-result').slideDown(300);
                }
                return;
            }
            button.text('Error!');
            button.addClass('btn-error');
            setTimeout(function(){
                button.text('Please Try Again');
            },1500);
            setTimeout(function(){
                button.removeClass('btn-error');
                button.html(button.data('action') +' <i class="fas fa-chevron-right"></i>');
            },3000);
        }).fail(function(xhr, ajaxOptions, thrownError){
            log(xhr, ajaxOptions, thrownError);
            $j('button.submit')
            .removeClass('working')
            .addClass('btn-error')
            .removeAttr('disabled');
            $j('button.submit').text('Submission Error: '+ xhr.statusText);
            setTimeout(function() {
                $j('button.submit').text('Please Try Again');
            }, 2000);
            setTimeout(function() {
                $j('button.submit').text('Submit Application');
                $j('button.submit')
                .removeClass('disabled btn-error')
                .prop('disabled', false);;
            }, 5000);
        });
    }
    if ( !! $j('#data-table').length ) {
        if ($j.fn.tablesorter) {
            $j('#data-table:not(.sticky-th)').tablesorter();
        } else {
            console.error("TableSorter plugin is not available.");
        }
    }
    if ( !! $j('#data-table2').length ) {
        if ($j.fn.tablesorter) {
            $j('#data-table2:not(.sticky-th)').tablesorter();
        } else {
            console.error("TableSorter plugin is not available.");
        }
    }
    if (!!$j('#search-filter').length) {
        $j('.data-table-wrap').after('<div style="display:none;" class="no-results">No Results for <strong class="search-filter-text"></strong></div>');
        $j.extend($j.expr[":"], {
            "containsIN": function (elem, i, match, array) {
                return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
        });
        var updateList = function () {
            var allRows = $j('.data-table tbody tr');
            allRows.removeClass('hiddenRow');
            var clickedText = $j('#search-filter').val();
            allRows.each(function () {
                if (!$j(this).is(':containsIN("' + clickedText + '")')) {
                    $j(this).addClass('hiddenRow');
                }
            });
            var activeRows = $j('.data-table tbody tr:not(.hiddenRow').length;
            $j('.search-filter-text').html(clickedText);
            if ( activeRows < 1 ) {
                $j('.no-results').show();
            } else {
                $j('.no-results').hide();
            }
            if ( $j('.warranty-mobile-list').length ) {
                var allRows = $j('.warranty-mobile-list');
                allRows.removeClass('hiddenRow');
                allRows.each(function () {
                    if (!$j(this).is(':containsIN("' + clickedText + '")')) {
                        $j(this).addClass('hiddenRow');
                    }
                });
            }
        }
        $j('#search-filter').keyup(function (e) {
            updateList();
        });
        $j('#search-filter').blur(function (e) {
            updateList();
        });
    }
    if ( typeof(acf) != 'undefined' && acf != null ) {
        acf.enableSubmit = function( $submit ){
            return $submit.removeClass('disabled').removeAttr('disabled').fadeTo(300,1);
        };
        acf.disableSubmit = function( $submit ){
            return $submit.addClass('disabled').attr('disabled', 1).fadeTo(300,.2);
        };
    }
    if ( $j('form.bulk-send').length ) {
        function updateCounts() {
            let adminChecked = $j('.admin-checks input:checked').length;
            $j('.notify-admin-count').text(adminChecked);

            let userChecked = $j('.user-checks input:checked').length;
            $j('.notify-user-count').text(userChecked);

            let actualNotifyCount = adminChecked + userChecked;
            $j('input[name="notify_count"]').attr({
                'data-parsley-pattern': '^' + actualNotifyCount + '$',
                'data-parsley-pattern-message': 'The value must be exactly ' + actualNotifyCount
            });
            $j('.notify-total-count').text(actualNotifyCount);
        }
        updateCounts();
        $j('form.bulk-send').on('change', 'input', updateCounts);
        $j('form h2').css({'cursor':'pointer'});
        $j('form h2').on('click', function() {
            // Toggle active class on the <h2>
            $j(this).toggleClass('active');
            // Find the next div element and check/uncheck all checkboxes
            let checkboxes = $j(this).next('div').find('input[type="checkbox"]');
            if ($j(this).hasClass('active')) {
                // Check only unchecked checkboxes
                checkboxes.each(function() {
                    if (!$j(this).prop('checked')) {
                        $j(this).prop('checked', true);
                    }
                });
            } else {
                // Uncheck only checked checkboxes
                checkboxes.each(function() {
                    if ($j(this).prop('checked')) {
                        $j(this).prop('checked', false);
                    }
                });
            }
            updateCounts();
        });
    }
}

